import React from 'react';
import * as d3 from 'd3';
import { formatCurrency } from './useRenderChart';
import { RSNA_EXPENSES } from './prepareData';

const ROI = props => {
  const { roiTable } = props;
  const BREAKDOWN = {
    'Conference costs': '$33,516',
    'Airfare / Lodging': '$18,952',
    'Marketing / Advertising costs': '$31,728',
    'Total cost of RSNA18': '$84,196'
  };
  // console.log(roiTable);

  const cost = Object.entries(BREAKDOWN).map(([key, value]) => {
    return (
      <tr key={key}>
        <td>{key}</td>
        <td style={{ color: 'red' }}>{value}</td>
      </tr>
    );
  });

  const RETURN_LINES = ['PET', 'MRI'];
  let acc = 0;
  const returns = Array.from(roiTable.entries())
    .filter(([key]) => RETURN_LINES.includes(key))
    .map(([key, value]) => {
      acc += value;
      return (
        <tr key={key}>
          <td>{`${key} Leads & Deals`}</td>
          <td style={{ color: 'green' }}>{formatCurrency(value)}</td>
        </tr>
      );
    });

  return (
    <>
      <h3
        style={{
          textAlign: 'center',
          maxWidth: '500px',
          margin: '2rem auto 1rem auto',
        }}
      >
        RSNA 2018 ROI
      </h3>
      <table
        style={{
          maxWidth: '500px',
          margin: '0 auto 4rem auto',
        }}
      >
        <thead>
          <tr>
            <td>Item</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody>
          {cost}
          {returns}
          <tr>
            <td>Total Return</td>
            <td style={{ color: 'green' }}>{formatCurrency(acc)}</td>
          </tr>
          <tr>
            <td>ROI</td>
            <td style={{ color: 'green', fontWeight: '800' }}>
              {d3.format('.0%')(acc / RSNA_EXPENSES)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ROI;
