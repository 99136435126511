/* eslint-disable no-param-reassign */
/* global document */
import { useState, useEffect } from 'react';
import * as d3Base from 'd3';
import * as d3Sankey from 'd3-sankey';

import { reduceData, generateRoiTable, RSNA_EXPENSES } from './prepareData';
import CSV5 from './sankey5v3.csv';

const d3 = { ...d3Base, ...d3Sankey };

const RENDERER = {};

const CAT_10 = [
  '#1f77b4',
  '#9467bd',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
  '#76b7b2'
];

const data = reduceData(CSV5);
const { flow, roi, rawLinks } = data;

const format = d3.format(',.0f');
const createOrSelectGroup = (root, id) => {
  if (document.getElementById(id) === null) {
    root.append('g').attr('id', id);
  }
  return root.select(`#${id}`);
};

const currency = d3.format('$,.3r');

export const formatCurrency = val => {
  if (!val) return '';
  return currency(val);
};

export const useRenderChart = (width, height, display) => {
  const [roiHash, setRoiTable] = useState(new Map());

  useEffect(
    () => {
      const chartData = display === 'Flow' ? flow : roi;
      RENDERER[display](width, height, chartData, setRoiTable);
    },
    [width, height, display]
  );
  return roiHash;
};

RENDERER.ROI = (width, height, chartData, setRoiTable) => {
  const color = node => {
    switch (node) {
      case 'RSNA18 Expenses':
      case 'Exhibiting costs':
      case 'Travel / lodging':
      case 'Marketing':
        return 'red';
      case 'RSNA18 Value Created':
      case 'MRI':
      case 'PET':
        return '#59a14f';
      default:
        return 'green';
    }
  };
  const { links, nodes } = chartData;

  const edgeColor = 'path';
  const svg = d3.select('svg');
  svg.selectAll('*').remove();
  // const tooltip = d3.select('#tooltip');

  // we're going to join 2 tables
  // const chartData2 = { links: links2, nodes };
  d3
    .sankey()
    .nodeId(l => l.name)
    .nodeAlign(d3.sankeyLeft)
    .nodeWidth(15)
    .nodePadding(10)
    .extent([[1, 1], [width - 1, height - 5]])(chartData);

  // shift the expense nodes halfway down the chart (as measured by the RSNA18 Value Created node's position)
  const heightNode = nodes.filter(el => el.name === 'RSNA18 Value Created')[0];

  let yStart;
  let yEnd;
  const expenseNodes = nodes.filter(el => {
    if (el.name === 'Exhibiting costs') {
      yStart = el.y0;
      return true;
    }

    if (el.name === 'Marketing') {
      yEnd = el.y1;
      return true;
    }

    if (el.name === 'Travel / lodging' || el.name === 'RSNA18 Expenses') {
      return true;
    }
    return false;
  });
  const heightOffset = (heightNode.y1 - heightNode.y0 - (yEnd - yStart)) / 2;

  expenseNodes.forEach(node => {
    node.y0 += heightOffset;
    node.y1 += heightOffset;
  });

  links.filter(el => el.target.name === 'RSNA18 Expenses').forEach(link => {
    link.y0 += heightOffset;
    link.y1 += heightOffset;
  });

  const roiTable = generateRoiTable(flow, rawLinks);
  setRoiTable(roiTable);

  console.log(links, nodes);

  // ***Build Chart***
  // clear chart on transitions -- TODO: animate instead
  svg.selectAll('*').remove();

  const nodesGroup = createOrSelectGroup(svg, 'nodes-group');

  nodesGroup
    .selectAll('rect')
    .data(nodes)
    .exit()
    // .transition()
    // .styleTween('opacity', () => d3.interpolate(1, 0))
    .remove();

  // format nodes
  nodesGroup
    .selectAll('rect')
    .data(nodes)
    .enter()
    .append('rect')
    .append('title')
    .text(d => {
      if (d.depth <= 1) {
        // chart root nodes
        return `${d.name}\nCosts: ${formatCurrency(d.value)}`;
      }

      return `${d.name}\nValue created: ${formatCurrency(d.value)}`;
    });

  nodesGroup
    .selectAll('rect')
    .attr('x', d => d.x0)
    .attr('y', d => d.y0)
    .attr('height', d => d.y1 - d.y0)
    .attr('width', d => d.x1 - d.x0)
    .attr('fill', d => color(d.displayName || d.name));

  // format links
  const linksGroup = createOrSelectGroup(svg, 'links-group')
    .attr('fill', 'none')
    .attr('stroke-opacity', 0.5)
    .selectAll('g')
    .data(links);

  // exit outdated
  linksGroup.exit().remove();

  const newLinkGroups = linksGroup
    .enter()
    .append('g')
    .style('mix-blend-mode', 'multiply');

  // add gradients to new links
  const newGradients = newLinkGroups
    .append('linearGradient')
    .attr('id', (d, i) => {
      const id = `link-${i}`;
      // eslint-disable-next-line no-param-reassign
      d.uid = id;
      return id;
    })
    .attr('gradientUnits', 'userSpaceOnUse');

  newGradients
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', d => color(d.source.displayName || d.source.name));

  newGradients
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', d => color(d.target.displayName || d.target.name));

  // update gradient positions
  createOrSelectGroup(svg, 'links-group')
    .selectAll('g')
    .select('linearGradient')
    .attr('x1', d => d.source.x1)
    .attr('x2', d => d.target.x0);

  newLinkGroups
    .append('path')
    .attr('stroke', d => {
      switch (edgeColor) {
        case 'path':
          return `url('#${d.uid}')`;
        case 'input':
          return color(d.source.name);
        case 'output':
          return color(d.target.name);
        default:
          return '#aaa';
      }
    })
    .append('title')
    .text(d => {
      if (d.source.name === 'RSNA18 Expenses') {
        return `${d.source.name} → Value Created\n${formatCurrency(d.value)} returned ${formatCurrency(d.target.value)} worth of deals\nROI: ${d3.format('.0%')(d.target.value / d.source.value)}`;
      }

      return `${d.source.name} → ${d.target.displayName || d.target.name}\n${formatCurrency(d.value)}\n${Array.from(d.institutions).join('\n')}`;

      // }:\n${Array.from(d.institutions).join('\n')}`;
    });

  // update path positions
  createOrSelectGroup(svg, 'links-group')
    .selectAll('g')
    .select('path')
    .attr('d', d => {
      // we need to draw our own link from RSNA18 Expenses to RSNA18 Value Created
      if (d.source.name === 'RSNA18 Expenses') {
        const draw = context => {
          const {
            source: { x0: sx0, x1: sx1, y0: sy0, y1: sy1 },
            target: { x0: tx0, x1: tx1, y0: ty0, y1: ty1 },
          } = d;

          const yLength = ty1 - sy1;
          const xLength = tx0 - sx1;
          const cpx = tx0 - 0.1 * xLength;
          const cpy = ty1 - 0.2 * yLength;
          const cpx1 = sx1 + 0.5 * xLength;
          const cpy1 = ty1 - 0.8 * yLength;
          const cptx = sx0 + 0.3 * xLength;
          const cpty = sy0 - 0.1 * yLength;
          const cptx1 = tx0 - 0.3 * xLength;
          const cpty1 = ty0 + 0.5 * yLength;

          context.moveTo(sx1, sy0);
          context.bezierCurveTo(cptx, cpty, cptx1, cpty1, tx0, ty0);
          context.lineTo(tx0, ty1);
          context.bezierCurveTo(cpx, cpy, cpx1, cpy1, sx1, sy1);
          context.closePath();

          return context;
        };

        return draw(d3.path()).toString();
      }

      return d3.sankeyLinkHorizontal()(d);
    })
    .attr('stroke-width', d => {
      if (d.source.name === 'RSNA18 Expenses') {
        return 1;
      }
      return Math.max(1, d.width);
    })
    .attr('opacity', d => {
      if (d.source.name === 'RSNA18 Expenses') {
        return 0.5;
      }
      return 1;
    })
    .attr('fill', d => {
      if (d.source.name === 'RSNA18 Expenses') {
        return `url('#${d.uid}')`;
      }
      return null;
    });

  const labelsGroup = createOrSelectGroup(svg, 'labels-group')
    .style('font', '10px sans-serif')
    .selectAll('text')
    .data(nodes);

  labelsGroup.exit().remove();

  labelsGroup
    .enter()
    .append('text')
    .text(d => d.displayName || d.name)
    .merge(labelsGroup)
    .attr('x', d => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
    .attr('y', d => (d.y1 + d.y0) / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', d => (d.x0 < width / 2 ? 'start' : 'end'));
};

RENDERER.Flow = (width, height, chartData, setRoiTable) => {
  const defaultColorScheme = d3.scaleOrdinal(CAT_10);
  const color = node => {
    switch (node) {
      case 'RSNA18':
        return '#8c564b';
      case 'Normal':
        return '#edc949';
      case 'Warm':
        return '#f28e2c';
      case 'Hot':
        return '#e15759';
      case 'MRI':
        return '#59a14f';
      case 'PET':
        return '#4e79a7';
      case 'No deal created':
        return '#7f7f7f';
      case 'Won':
        return '#2ca02c';
      case 'Lost':
        return '#d62728';
      default:
        return defaultColorScheme(node);
    }
  };
  const { links, nodes } = chartData;

  const edgeColor = 'path';
  const svg = d3.select('svg');

  d3
    .sankey()
    .nodeId(l => l.name)
    .nodeAlign(d3.sankeyLeft)
    .nodeWidth(15)
    .nodePadding(10)
    .extent([[1, 1], [width - 1, height - 5]])(chartData);

  const roiTable = generateRoiTable(flow, rawLinks);
  setRoiTable(roiTable);

  // clear chart on transitions -- TODO: animate instead
  svg.selectAll('*').remove();

  // console.log(roiTable);
  const nodesGroup = createOrSelectGroup(svg, 'nodes-group');

  nodesGroup
    .selectAll('rect')
    .data(nodes)
    .exit()
    .transition()
    .styleTween('opacity', () => d3.interpolate(1, 0))
    .remove();

  // format nodes
  nodesGroup
    .selectAll('rect')
    .data(nodes)
    .enter()
    .append('rect')
    .append('title')
    .text(d => {
      const roi = formatCurrency(roiTable.get(d.name));
      if (d.depth === 0) {
        // entry to sankey -- display expenses $96,034.86
        const expenses = `Expenses: ${formatCurrency(RSNA_EXPENSES)}`;
        const roiPercentage = d3.format('.0%')(
          roiTable.get(d.name) / RSNA_EXPENSES
        );
        return `
              ${d.name}\n${expenses}\nGenerated ${format(d.value)} leads${
          roi ? ` worth ${roi}` : ''
        }\nROI: ${roiPercentage}`;
      }

      return `
            ${d.name}\n${format(d.value)} leads${roi ? ` (worth ${roi})` : ''}`;
    });

  nodesGroup
    .selectAll('rect')
    .attr('x', d => d.x0)
    .attr('y', d => d.y0)
    .attr('height', d => d.y1 - d.y0)
    .attr('width', d => d.x1 - d.x0)
    .attr('fill', d => color(d.displayName || d.name));

  // format links
  const linksGroup = createOrSelectGroup(svg, 'links-group')
    .attr('fill', 'none')
    .attr('stroke-opacity', 0.5)
    .selectAll('g')
    .data(links);

  // exit outdated
  linksGroup.exit().remove();

  const newLinkGroups = linksGroup
    .enter()
    .append('g')
    .style('mix-blend-mode', 'multiply');

  // add gradients to new links
  const newGradients = newLinkGroups
    .append('linearGradient')
    .attr('id', (d, i) => {
      const id = `link-${i}`;
      // eslint-disable-next-line no-param-reassign
      d.uid = id;
      return id;
    })
    .attr('gradientUnits', 'userSpaceOnUse');

  newGradients
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', d => color(d.source.displayName || d.source.name));

  newGradients
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', d => color(d.target.displayName || d.target.name));

  // update gradient positions
  createOrSelectGroup(svg, 'links-group')
    .selectAll('g')
    .select('linearGradient')
    .attr('x1', d => d.source.x1)
    .attr('x2', d => d.target.x0);

  newLinkGroups
    .append('path')
    .attr('stroke', d => {
      switch (edgeColor) {
        case 'path':
          return `url('#${d.uid}')`;
        case 'input':
          return color(d.source.name);
        case 'output':
          return color(d.target.name);
        default:
          return '#aaa';
      }
    })
    .append('title')
    .text(d => {
      const roi = formatCurrency(d.dealValue);

      return `${d.source.name} → ${d.target.name}\n${format(d.value)} leads${
        roi ? ` (worth ${roi})` : ''
      }:\n${d.institutions.join('\n')}`;
    });

  // update path positions
  createOrSelectGroup(svg, 'links-group')
    .selectAll('g')
    .select('path')
    .attr('d', d3.sankeyLinkHorizontal())
    .attr('stroke-width', d => Math.max(1, d.width));

  const labelsGroup = createOrSelectGroup(svg, 'labels-group')
    .style('font', '10px sans-serif')
    .selectAll('text')
    .data(nodes);

  labelsGroup.exit().remove();

  labelsGroup
    .enter()
    .append('text')
    .text(d => d.displayName || d.name)
    .merge(labelsGroup)
    .attr('x', d => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
    .attr('y', d => (d.y1 + d.y0) / 2)
    .attr('dy', '0.35em')
    .attr('text-anchor', d => (d.x0 < width / 2 ? 'start' : 'end'));
};
