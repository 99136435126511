/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import * as d3Base from 'd3';
import * as d3Sankey from 'd3-sankey';

const d3 = { ...d3Base, ...d3Sankey };
const LEAVES = [
  'MRI-Deployed',
  'MRI-Won',
  'MRI-Prospect',
  'MRI-Evaluation',
  'MRI-Demo',
  'MRI-Quote',
  'PET-Deployed',
  'PET-Won',
  'PET-Prospect',
  'PET-Evaluation',
  'PET-Demo',
  'PET-Quote',
];

export const RSNA_EXPENSES = 84196.18;
const EXPENDITURES = [
  {
    name: 'Exhibiting costs',
    value: 33516.2,
    details: 'Booth fees, Lunch and Learn, Exhibition charges',
  },
  { name: 'Travel / lodging', value: 18951.8, details: 'Airfare and lodging' },
  {
    name: 'Marketing',
    value: 31728.18,
    details: 'Banners, Booth giveaways, Advertising',
  },
];

export const reduceData = rawData => {
  const links = d3.csvParse(rawData, d3.autoType);
  const rawLinks = [...links];
  const nodes = Array.from(
    new Set(links.flatMap(l => [l.source, l.target])),
    name => ({ name })
  );

  // combine edges into one entry
  const table = new Map();
  const displayNameHash = {};
  const linkValueHash = {};

  nodes.forEach(node => {
    const { name } = node;
    table.set(name, new Map());
  });

  links.forEach(link => {
    const { source, target, institution, targetDisplay, dealValue } = link;

    let institutionsTargeted = table.get(source).get(target);
    if (institutionsTargeted === undefined) {
      institutionsTargeted = new Set();
      table.get(source).set(target, institutionsTargeted);
    }
    institutionsTargeted.add(institution);

    // set dealValue for target
    if (dealValue) {
      const accDealValue = table.get(target).get('dealValue') || 0;
      table.get(target).set('dealValue', accDealValue + dealValue);
    }

    // set display names if available
    if (targetDisplay) {
      displayNameHash[target] = targetDisplay;
    }

    // set the value of this link
    if (source && target) {
      // links 2 nodes
      if (!linkValueHash[source]) linkValueHash[source] = {};
      if (!linkValueHash[source][target]) linkValueHash[source][target] = 0;
    }
    linkValueHash[source][target] += dealValue || 0;
  });
  linkValueHash.RSNA18.Hot = Object.values(linkValueHash.Hot).reduce(
    (acc, curr) => acc + curr,
    0
  );
  linkValueHash.RSNA18.Warm = Object.values(linkValueHash.Warm).reduce(
    (acc, curr) => acc + curr,
    0
  );
  linkValueHash.RSNA18.Normal = Object.values(linkValueHash.Normal).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const reducedLinks = [];
  Array.from(table.entries()).forEach(edge => {
    const [source, nodeData] = edge;
    // const dealValue = nodeData.has('dealValue')
    //   ? nodeData.get('dealValue')
    //   : null;
    Array.from(nodeData.entries()).forEach(targetEntry => {
      const [target, institutionsSet] = targetEntry;
      if (target === 'dealValue') return; // agg ROI on target node

      const institutions = Array.from(institutionsSet);
      const link = {
        source,
        target,
        value: institutionsSet.size,
        institutions,
        dealValue: linkValueHash[source][target],
      };
      reducedLinks.push(link);
    });
  });

  // add display name & deal value to nodes
  nodes.forEach(node => {
    // eslint-disable-next-line no-param-reassign
    node.displayName = displayNameHash[node.name];
    const { name } = node;
    const dealValue =
      (table.get(name) && table.get(name).get('dealValue')) || null;
    // eslint-disable-next-line no-param-reassign
    node.dealValue = dealValue;
  });

  // generate ROI chart data
  console.log('GENERATING LINKS FOR ROI CHART');

  // aggregate base links
  const roiLinkHash = {};
  LEAVES.forEach(targetNode => {
    roiLinkHash[targetNode] = {
      source: targetNode.slice(0, 3),
      target: targetNode,
      value: 0,
      institutions: new Set(),
    };
  });

  links.filter(el => LEAVES.includes(el.target)).forEach(link => {
    const { institution, dealValue = 0, target } = link;
    roiLinkHash[target].institutions.add(institution);
    roiLinkHash[target].value += dealValue;
  });

  const roiLinks = Object.values(roiLinkHash).filter(el => el.value > 0);

  // reorganize chart here if we want to do away with leaves

  // add expense links
  const expenseLinks = EXPENDITURES.map(expense => {
    return {
      source: expense.name,
      target: 'RSNA18 Expenses',
      value: expense.value,
      institutions: new Set(),
      details: expense.details,
    };
  });

  // reducer for attributing deal returns to PET of MR nodes
  const aggROI = source => (acc, curr) => {
    acc += curr.source === source ? curr.value : 0;
    return acc;
  };

  expenseLinks.push(
    {
      source: 'RSNA18 Expenses',
      target: 'RSNA18 Value Created',
      value: RSNA_EXPENSES,
      // value: roiLinks.reduce(aggROI('MRI'), 0),
      institutions: new Set(),
    },
    {
      source: 'RSNA18 Value Created',
      target: 'MRI',
      // value: RSNA_EXPENSES / 2,
      value: roiLinks.reduce(aggROI('MRI'), 0),
      institutions: new Set(),
    },
    {
      source: 'RSNA18 Value Created',
      target: 'PET',
      // value: RSNA_EXPENSES / 2,
      value: roiLinks.reduce(aggROI('PET'), 0),
      institutions: new Set(),
    }
  );

  const roiChartLinks = [...expenseLinks, ...roiLinks];
  // const roiChartLinks2 = [...expenseLinks2, ...roiLinks];

  const roiNodes = Array.from(
    new Set(roiChartLinks.flatMap(l => [l.source, l.target])),
    name => ({ name })
  );

  // display names for ROI nodes
  roiNodes.forEach(node => {
    node.displayName = displayNameHash[node.name];
  });

  // ingress node: Expenditures

  return {
    flow: { links: reducedLinks, nodes },
    roi: { links: roiChartLinks, nodes: roiNodes },
    rawLinks,
  };
};

export const generateRoiTable = (data, rawLinks) => {
  const roiTable = new Map();

  for (let i = 0; i < rawLinks.length; ++i) {
    const { source, target, dealValue } = rawLinks[i];
    if (LEAVES.includes(target)) {
      const modality = source.slice(0, 3);
      // fill out leaf values, leaf parents, and leaf grandparents (PET / MRI)
      roiTable.set(source, (roiTable.get(source) || 0) + dealValue);
      roiTable.set(target, (roiTable.get(target) || 0) + dealValue);
      roiTable.set(modality, (roiTable.get(modality) || 0) + dealValue);
    }
  }

  // aggregate Warm / Hot / Normal from modalities
  const PARENTS = ['Warm', 'Hot', 'Cold'];
  PARENTS.forEach(parent => {
    roiTable.set(
      parent,
      (roiTable.get(`PET-${parent}`) || 0) +
        (roiTable.get(`MRI-${parent}`) || 0)
    );
    roiTable.set('RSNA', (roiTable.get('RSNA') || 0) + roiTable.get(parent));
  });

  return roiTable;
};

function clone(obj) {
  // base case
  if (obj === null) return null;
  if (typeof obj !== 'object') return obj;

  // breaking out cases to avoid regenerator runtime polyfill in for...of:
  if (Array.isArray(obj)) {
    return obj.map(clone);
  }

  // obj:
  const clonedObj = {};
  Object.keys(obj).forEach(key => {
    clonedObj[key] = clone(obj[key]);
  });
  return clonedObj;
}
