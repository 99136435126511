import React, { useState } from 'react';
import Layout from '../../components/CleanLayout';
import SigninController from '../../components/login/SigninController.container';
import Signin from '../../components/login/Signin.container';
import SigninSwitch from '../../components/login/SigninSwitch';
import SignoutButton from '../../components/login/SignoutButton';
// import stylesModule from '../../components/graphing/PET/graphStyles.module.scss';
// import EaseOfAccess from '../../components/graphing/PET/EaseOfAccess';
import Sankey from '../../components/graphing/LeadAnalytics/LongitudinalSankey';

const METADATA = [
  {
    name: 'description',
    content: 'International PET market',
  },
  {
    name: 'keywords',
    content: 'Subtle Medical, PET, international',
  },
];

const Graph = () => {
  const [graph, setGraph] = useState('Flow');
  return (
    <Layout pageTitle="RSNA18 Longitudinal Analysis" metadata={METADATA}>
      <SigninController />
      <SignoutButton />
      <h1 className="admin--title">Institutional Leads from RSNA 18</h1>
      <Signin />
      <SigninSwitch>
        <button
          type="button"
          style={{ margin: '0 auto 1rem auto', display: 'block' }}
          onClick={() => {
            setGraph(graph === 'Flow' ? 'ROI' : 'Flow');
          }}
        >
          {graph === 'Flow' ? 'Show ROI Chart' : 'Show Flow Chart'}
        </button>
        <Sankey display={graph} />
      </SigninSwitch>
    </Layout>
  );
};

export default Graph;
