/* eslint-disable no-underscore-dangle */
/**
 * Sankey chart:
 * https://observablehq.com/@d3/sankey-diagram#sankey
 * https://talk.observablehq.com/t/how-do-i-work-with-the-d3-sankey-example/1696/3
 * https://bost.ocks.org/mike/selection/
 * https://observablehq.com/@d3/color-schemes
 */

import React, { useState } from 'react';
import useThrottleResize from '../../../hooks/useThrottleResize';
import useEventListener from '../../../hooks/useEventListener';
import useDimensions, { getDimensions } from '../../../hooks/useDimensions';
import { useRenderChart } from './useRenderChart';
import ROI from './ROI';

import styles from './chartStyles.module.scss';

const RSNALeadAnalysis = ({ children, display }) => {
  const SCALE = { x: 1, y: 0.8 };
  const MIN_CHART_WIDTH = 500;
  const [INITIAL_WIDTH, INITIAL_HEIGHT] = getDimensions(1000, 800, 5, 0);
  // state for SVG size
  const [width, setWidth] = useState(INITIAL_WIDTH);
  const [height, setHeight] = useState(INITIAL_HEIGHT);

  if (typeof window !== 'undefined') {
    useThrottleResize();
    useEventListener('throttledResize', () =>
      useDimensions(setWidth, setHeight, { xOffset: 5 })
    );
  }

  const actualWidth = Math.max(MIN_CHART_WIDTH, width * SCALE.y);
  const actualHeight = height * SCALE.y;

  // render either ROI or flow chart
  const roiTable = useRenderChart(actualWidth, actualHeight, display);

  return (
    <React.Fragment>
      <div
        id="graph"
        className={styles.graphContainer}
        // data-hidelabels={labelsOff}
      >
        <div id="tooltip" className={styles.tooltip} />
        <svg
          width={actualWidth}
          height={actualHeight}
          className={styles.canvas}
        >
          <defs>
            <marker
              id="arrowhead"
              refX={9}
              refY={5}
              markerWidth={20}
              markerHeight={20}
              orient="auto"
            >
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>
          </defs>
        </svg>
        <div className={styles.legendContainer}>{children}</div>
      </div>
      <ROI roiTable={roiTable} />
    </React.Fragment>
  );
};

export default RSNALeadAnalysis;
